import React from 'react';
import { Route, Routes as AppRoutes } from 'react-router-dom';
import { Routes as SiteRoutes } from './routes/';
import { E404 } from '../pages/error';
import { SiteLayout } from '../layout';

const Routers = () => {
  return (
      <AppRoutes>
          <Route path="/" element={<SiteLayout />}>
              {SiteRoutes.map((item, index) => {
                  const Component = item.component;
                  return (
                      <Route
                          path={item.path}
                          index={item.index}
                          {...item.ind}
                          element={
                              <React.Suspense fallback={<div>...</div>}>
                                  {<Component />}
                              </React.Suspense>
                          }
                          key={index}
                      />
                  );
              })}
          </Route>
          <Route path="*" element={<E404 />} />
      </AppRoutes>
  )
};
export default Routers;

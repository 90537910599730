import { lazy } from 'react';

const Siteroutes = [
  {
    ind: {
      index: true,
    },
    component: lazy(() => import('../../pages/home')),
    exact: true,
  },
  {
    path: '/contact',
    component: lazy(() => import('../../pages/contact')),
    ind: {},
    exact: true,
  },
  {
    path: '/services',
    component: lazy(() => import('../../pages/services')),
    ind: {},
    exact: true,
  },
  {
    path: '/services/:id',
    component: lazy(() => import('../../pages/service-single')),
    ind: {},
    exact: true,
  },
  {
    path: '/apropos-de-nous',
    component: lazy(() => import('../../pages/about')),
    ind: {},
    exact: true,
  },
  {
    path: '/activites',
    component: lazy(() => import('../../pages/activites')),
    ind: {},
    exact: true,
  },
  {
    path: '/activites/:id',
    component: lazy(() => import('../../pages/article-single')),
    ind: {},
    exact: true,
  },
  {
    path: '/projects',
    component: lazy(() => import('../../pages/projects')),
    ind: {},
    exact: true,
  },
  {
    path: '/materiels',
    component: lazy(() => import('../../pages/materiels')),
    ind: {},
    exact: true,
  },
  {
    path: '/projects/:id',
    component: lazy(() => import('../../pages/project-single')),
    ind: {},
    exact: true,
  },
];

export default Siteroutes;

import React, { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { SiteLoader } from '../components/elements/loaders';
const Site = lazy(() => import('./site'));
export const SiteLayout = (props) => {
  return (
    <React.Suspense fallback={<SiteLoader />}>
      <Site>
        <Outlet {...props} />
      </Site>
    </React.Suspense>
  );
};
